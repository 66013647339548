import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Box } from '@primer/react';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CustomVideoPlayer = makeShortcode("CustomVideoPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/224812709-4bc4d525-92e5-49e8-9a36-264af2ac1c0c.png" />
    <h2>{`Usage`}</h2>
    <p>{`Use a text input with tokens for a value that is a list. Items can be added or removed from the list, but the individual items cannot be edited inline.`}</p>
    <p>{`This kind of input is most commonly used with an `}<a parentName="p" {...{
        "href": "/components/autocomplete"
      }}>{`autocomplete`}</a>{` to let users select items from a predetermined list of options.`}</p>
    <h2>{`Anatomy`}</h2>
    <p>{`Same as the `}<a parentName="p" {...{
        "href": "/components/text-input"
      }}>{`text input`}</a>{`, but the input's value is a list instead of a single string of text.`}</p>
    <h2>{`Options`}</h2>
    <p>{`The text input can be configured with all of the same options as the regular `}<a parentName="p" {...{
        "href": "/components/text-input"
      }}>{`text input`}</a>{` component.`}</p>
    <h3>{`Wrapping`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/224812712-c3552b98-3064-4cac-83f0-7a83eb3e2a11.png" />
    <p>{`The list of tokens can be constrained to a single line or be wrapped to multiple lines. When wrapping is disabled and the list exceed the width of the input, the input will scroll horizontally.`}</p>
    <h3>{`Max height`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/224812714-c349ff71-7cf8-400f-b2be-01ef1e8cf997.png" />
    <p>{`When tokens wrap, the height of the input can be constrained to a maximum height. Once the rows exceed the maximum height, the input will scroll vertically.`}</p>
    <h3>{`Truncation`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/224812715-7382f758-3122-4da7-b742-34180876042f.png" />
    <p>{`You may choose to limit the number of tokens that are visible. However, all tokens will be visible while the text input is focused.`}</p>
    <h3>{`Token variants`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/224812717-20c412d7-24c0-4b94-a32a-7d3e7bc60468.png" />
    <p>{`You may choose any of Primer's token components, but you cannot mix different token components in a single input. You may also choose the size of the tokens and whether or not the token's remove button is shown.`}</p>
    <h2>{`Interactions`}</h2>
    <h3>{`Move focus between tokens`}</h3>
    <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
  <Box flex={1} mdxType="Box">
        <p>{`When tabbing to the input, the `}<inlineCode parentName="p">{`<input />`}</inlineCode>{` element receives focus. Then, when the text cursor is at the beginning of
the input, users can use the `}<inlineCode parentName="p">{`Left Arrow`}</inlineCode>{` key to move focus to the last token. Once any token has focus, users can
use the `}<inlineCode parentName="p">{`Right Arrow`}</inlineCode>{` and `}<inlineCode parentName="p">{`Left Arrow`}</inlineCode>{` key to move focus to the previous or next token.`}</p>
  </Box>
  <CustomVideoPlayer width="456" loop src="https://user-images.githubusercontent.com/2313998/225961225-12d6e608-5c7d-46eb-bb28-dffb813d0914.mp4" mdxType="CustomVideoPlayer" />
    </Box>
    <h3>{`Remove tokens`}</h3>
    <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
  <Box flex={1} mdxType="Box">
        <p>{`A token may be removed by focusing it and pressing the `}<inlineCode parentName="p">{`Backspace`}</inlineCode>{` key, or by clicking the token's remove button.`}</p>
        <p>{`If a user keys `}<inlineCode parentName="p">{`Backspace`}</inlineCode>{` and the text cursor is at the begining of the input, the last token will be removed but
the input will be populated with it's text value.`}</p>
  </Box>
  <CustomVideoPlayer width="456" loop src="https://user-images.githubusercontent.com/2313998/225962383-166e55f4-9616-495a-a166-485c51e71b95.mp4" mdxType="CustomVideoPlayer" />
    </Box>
    <h2>{`Accessibility`}</h2>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="TextInputWithTokens" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../ui-patterns/forms/overview"
        }}>{`Forms`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/autocomplete"
        }}>{`Autocomplete`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/text-input"
        }}>{`Text input`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/tokens"
        }}>{`Tokens`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      